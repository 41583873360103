<div *ngIf="!isNew && application.id === 0; else editApplication">
  <div class="bg-gray-50 py-4 px-8 text-xl text-red-500 border-b border-gray-300">
    Ansøgning findes ikke
  </div>
</div>


<ng-template #editApplication>
  <div class="bg-gray-50 py-4 px-8 text-2xl text-gray-500 border-b border-gray-300">
    Tilknyttede filer til mine LAG ansøgninger
  </div>

  <form class="px-6 pt-6 pb-14 flex flex-col space-y-4">
    <form-group displayTitle="Opret ansøgning for LAG eller på vegne af en ansøger" *ngIf="(currentUserRole === Role.COORDINATOR) && !application.id" tooltip="Vælg om du vil oprette denne ansøgning på vegne af en ansøger, eller oprette for LAG">
      <div class="pt-1">
        <input type="radio" id="applicant" name="roleSelection" [value]="false" class="form-check-input" [(ngModel)]="isCoordinatorApplication">
        <label class="pl-2" for="applicant">Opret for ansøger</label>
      </div>
      <div class="pt-1">
          <input type="radio" id="coordinator" name="roleSelection" [value]="true" class="form-check-input" [(ngModel)]="isCoordinatorApplication">
          <label class="pl-2" for="coordinator">Opret for LAG</label>
      </div>
    </form-group>

    <form-group displayTitle="ID" *ngIf="application.uuid" tooltip="ID">
      <input type="text" class="bg-white px-3 py-2 w-full border border-gray-300" id="id" placeholder="" name="id"
        [ngModel]="application.id" disabled />
    </form-group>

    <form-group displayTitle="CVR" *ngIf="!isCoordinatorApplication && (currentUserRole !== Role.APPLICANT)" tooltip="CVR">
      <div *ngIf="edit && !isCVRValid" class="text-red-500 mb-1">Angiv CVR</div>
      <input type="text" 
            class="bg-white px-3 py-2 w-full border border-gray-300" 
            id="cvr" 
            [(ngModel)]="application.cvr" 
            (ngModelChange)="onCVRChange($event)"
            [ngModelOptions]="{standalone: true}"
            placeholder=""
            [disabled]="!(edit && currentUserRole === Role.COORDINATOR && !application.id)" />
    </form-group>

    <form-group displayTitle="Ansøgere" *ngIf="!isCoordinatorApplication && (currentUserRole === Role.COORDINATOR && !application.id)" tooltip="Ansøgere">
      <div *ngIf="edit && !isApplicantValid" class="text-red-500 mb-1">Angiv Ansøger</div>
      <select class="bg-white px-3 py-2 w-full border border-gray-300" id="companyName"
              [(ngModel)]="chosenApplicant"
              [ngModelOptions]="{standalone: true}"
              [disabled]="loading_applicants || applicants.length === 0 || !(edit && currentUserRole === Role.COORDINATOR && !application.id)">
        <option *ngIf="applicants.length === 0" [value]="emptyUser">Ingen ansøgere fundet under indtastet CVR</option>
        <option *ngFor="let applicant of applicants" [value]="applicant">{{ applicant.personName }}</option>
      </select>
    </form-group>

    <form-group displayTitle="Projektansøger" *ngIf="(currentUserRole !== Role.APPLICANT) && application.companyName && application.id" tooltip="Projektansøger">
      <input type="text" class="bg-white px-3 py-2 w-full border border-gray-300" id="companyName" placeholder="" name="companyName"
            [ngModel]="application.companyName" disabled />
    </form-group>

    <form-group displayTitle="Vælg ansøgningstype" tooltip="Vælg ansøgningstype">
      <div *ngIf="edit && !isApplicationTypeValid" class="text-red-500 mb-1">Vælg type</div>
      <div *ngFor="let applicationType of applicationTypes" class="pt-1">
        <input type="radio" id="{{ applicationType.name }}" value="{{ applicationType.name }}" name="applicationType"
          [(ngModel)]="application.applicationType" [disabled]="!(edit && (currentUserRole === Role.APPLICANT || (currentUserRole === Role.COORDINATOR && !application.id)))">
        <label class="pl-2" for="{{ applicationType.name }}">{{ applicationType.displayName }}</label>
      </div>
      <div class="mt-4 border border-gray-300 py-2 px-4" *ngIf="showFillValuesDropdown()">
        <label for="previousApplication">Udfyld LAG, projekttitel og e-mail fra tidligere tilsagns-ansøgning:</label>
        <div class="flex mt-1 flex-col gap-2">
          <select class="bg-white px-3 py-2 w-full border border-gray-300" id="previousApplication"
            name="previousApplication" [(ngModel)]="selectedPreviousCommitmentApplication">
            <option *ngFor="let previousCommitmentApplication of previousCommitmentApplications" id="{{ previousCommitmentApplication.id }}"
            [ngValue]="previousCommitmentApplication">{{ toLagOrganizationDisplayName(previousCommitmentApplication.lagOrganization) }}, {{ previousCommitmentApplication.projectTitle }}, {{ previousCommitmentApplication.projectCaseId }}, {{ previousCommitmentApplication.email }}</option>
          </select>
          <button class="py-2 px-3 bg-green-600 text-white max-w-max" (click)="selectPreviousCommitmentApplication()">Udfyld</button>
        </div>
      </div>
    </form-group>

    <form-group displayTitle="Hvilken LAG ansøger du under?" tooltip="Hvilken LAG ansøger du under?">
      <div *ngIf="edit && !isLagOrganizationValid" class="text-red-500 mb-1">Vælg LAG</div>
      <select class="bg-white px-3 py-2 w-full border border-gray-300" id="lagOrganization" name="lagOrganization"
        [(ngModel)]="application.lagOrganization" [disabled]="!edit || (currentUserRole !== Role.APPLICANT)">
        <option *ngFor="let lagOrganization of lagOrganizations" id="{{ lagOrganization.name }}"
          value="{{ lagOrganization.name }}">{{ lagOrganization.displayName }}</option>
      </select>
    </form-group>

    <form-group displayTitle="Projekttitel Tast selv" tooltip="Projekttitel fra Tast selv">
      <div *ngIf="edit && !isTitleValid" class="text-red-500 mb-1">Angiv projekttitel</div>
      <input type="text" class="bg-white px-3 py-2 w-full border border-gray-300" id="projectTitle" placeholder=""
        name="projectTitle" [(ngModel)]="application.projectTitle" [disabled]="!(edit && (currentUserRole === Role.APPLICANT || (currentUserRole === Role.COORDINATOR && !application.id)))" />
    </form-group>

    <form-group displayTitle="Journalnummer fra Tast selv" tooltip="Sagsnummer fra Tast selv">
      <div *ngIf="edit && !isCaseIdValid" class="text-red-500 mb-1">Angiv Sagsnummer</div>
      <div *ngIf="edit && applicationWithSimilarTypeAndCaseIdExists" class="text-red-500 mb-1">En ansøgning med den valgte type og Journalnummer eksisterer allerede. Vedhæft venligst filerne til den eksisterende ansøgning.</div>
      <input type="text" class="bg-white px-3 py-2 w-full border border-gray-300" id="projectCaseId" placeholder=""
        name="projectCaseId" [(ngModel)]="application.projectCaseId" [disabled]="!(edit && (currentUserRole === Role.APPLICANT || (currentUserRole === Role.COORDINATOR && !application.id)))" />
    </form-group>

    <form-group displayTitle="E-mail" tooltip="E-mail">
      <div *ngIf="edit && !isEmailValid" class="text-red-500 mb-1">Ugyldig e-mail</div>
      <input type="email" class="bg-white px-3 py-2 w-full border border-gray-300" id="email" name="email"
        [(ngModel)]="application.email" [disabled]="!(edit && (currentUserRole === Role.APPLICANT || (currentUserRole === Role.COORDINATOR && !application.id)))" />
    </form-group>

    <form-group displayTitle="Koordinators bilag" tooltip="{{ getContextHelp('coordinator_appendix_list') }}" *ngIf="currentUserRole !== Role.APPLICANT">
      <button class="px-3 py-2 bg-green-600 text-white inline-flex items-center" *ngIf="edit && !coordinatorFilesLoading"
              (click)="coordinatorFileSelect.click()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-4 h-4 mr-1"
            viewBox="0 0 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        <span>Tilføj filer..</span>
      </button>
      <input #coordinatorFileSelect type="file" hidden (change)="coordinatorFileSelected($event)" multiple />
      <div *ngIf="attemptToUploadInvalidCoordinatorAttachment" class="py-2 text-red-500 mb-1">
        Navnet på den fil, du forsøger at vedhæfte, indeholder ikke-tilladte specialtegn eller forkert filtype, og kan derfor ikke vedhæftes.<br/>
        Tilladte filtyper: pdf, doc, docx, xls, xlsx, png, jpg og gif.<br/>
        Omdøb filen og vedhæft igen.
      </div>

      <promis-spinner *ngIf="coordinatorFilesLoading"></promis-spinner>
      <div class="attachments mt-4" *ngIf="!coordinatorFilesLoading">
        <div class="[&:nth-child(even)]:bg-gray-200" *ngFor="let coordinatorFile of sortFiles(application.coordinatorFiles)">
          <attachment [file]="coordinatorFile" [readonly]="!edit || (currentUserRole !== Role.COORDINATOR)" [documentTypes]="coordinatorDocumentTypes" (deleteFile)="deleteCoordinatorFile($event)" (downloadFile)="downloadFile($event)"></attachment>
        </div>
      </div>
    </form-group>

    <form-group displayTitle="Bilag" tooltip="{{ getContextHelp('appendix_list') }}"> 
      <button class="px-3 py-2 bg-green-600 text-white inline-flex items-center" *ngIf="edit && !filesLoading && (currentUserRole === Role.APPLICANT || (!application.id && currentUserRole === Role.COORDINATOR))"
        (click)="fileSelect.click()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-4 h-4 mr-1"
          viewBox="0 0 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        <span>Tilføj filer..</span>
      </button>
      <input #fileSelect type="file" hidden (change)="fileSelected($event)" multiple />
      <div *ngIf="attemptToUploadInvalidAttachment" class="py-2 text-red-500 mb-1 space-y-3">
        Navnet på den fil, du forsøger at vedhæfte, indeholder ikke-tilladte specialtegn eller forkert filtype, og kan derfor ikke vedhæftes.<br/>
        Tilladte filtyper: pdf, doc, docx, xls, xlsx, png, jpg og gif.<br/>
        Omdøb filen og vedhæft igen.
      </div>

      <promis-spinner *ngIf="filesLoading"></promis-spinner>
      <div class="attachments mt-4" *ngIf="!filesLoading">
        <div class="[&:nth-child(even)]:bg-gray-200" *ngFor="let file of sortFiles(application.files)">
          <attachment [file]="file" [readonly]="!edit || (currentUserRole !== Role.APPLICANT && !(edit && currentUserRole === Role.COORDINATOR && !application.id))"
          [documentTypes]="documentTypes" (deleteFile)="deleteFile($event)" (downloadFile)="downloadFile($event)"></attachment>
        </div>
      </div>
    </form-group>

    <div class="bg-white py-6 flex space-x-4 items-center justify-center" *ngIf="edit && (currentUserRole === Role.APPLICANT)">
      <promis-button (click)="cancel()">Annullér</promis-button>
      <promis-button (click)="saveDraft()">Gem kladde</promis-button>
      <promis-button (click)="saveAndSendApplication()">Indsend filer til ansøgning</promis-button>
    </div>

    <div class="bg-white py-6 flex space-x-4 items-center justify-center" *ngIf="!edit && (currentUserRole === Role.APPLICANT)">
      <promis-button (click)="cancel()">Tilbage til forsiden</promis-button>
      <promis-button (click)="sendApplication()" [isDisabled]="!sendable">Indsend filer til ansøgning</promis-button>
    </div>

    <div class="bg-white py-6 flex space-x-4 items-center justify-center" *ngIf="edit && (currentUserRole === Role.COORDINATOR)">
      <promis-button (click)="cancelWithConfirmation()">Annullér</promis-button>
      <promis-button (click)="saveCoordinatorFilesToApplication()" *ngIf="application.id">Gem kladde</promis-button>
      <promis-button (click)="saveNewApplicationAsCoordinator()" *ngIf="!application.id">Opret som 'Indsendt til LAG'</promis-button>
      <promis-button (click)="saveAndSendApplicationBackToApplicantWithConfirmation()" *ngIf="!isCoordinatorApplication && application.id">Send tilbage til ansøger (manglende oplysninger)</promis-button>
      <promis-button (click)="returnNewApplicationAsCoordinator()" *ngIf="!isCoordinatorApplication && !application.id">Opret og send tilbage til ansøger (manglende oplysninger)</promis-button>
      <promis-button (click)="saveAndSendApplicationToAgencyWithConfirmation()" *ngIf="application.id">Send til Styrelsen</promis-button>
      <promis-button (click)="saveAndSendApplicationToAgencyAsCoordinator()" *ngIf="!application.id">Opret og send til Styrelsen</promis-button>
      <promis-button (click)="reject()" *ngIf="application.id">Afvis</promis-button>
      <promis-button (click)="rejectNewApplicationAsCoordinator()" *ngIf="!application.id">Opret og Afvis ansøgning</promis-button>
    </div>

    <div class="bg-white py-6 flex space-x-4 items-center justify-center" *ngIf="!edit && (currentUserRole === Role.COORDINATOR)">
      <promis-button (click)="cancel()">Tilbage til forsiden</promis-button>
      <promis-button (click)="sendApplicationBackToApplicantWithConfirmation()" *ngIf="!isCoordinatorApplication" [isDisabled]="!sendable">Send tilbage til ansøger (manglende oplysninger)</promis-button>
      <promis-button (click)="sendApplicationToAgencyWithConfirmation()" [isDisabled]="!sendable">Send til Styrelsen</promis-button>
      <promis-button (click)="reject()" [isDisabled]="!sendable">Afvis</promis-button>
    </div>

    <div class="bg-white py-6 flex space-x-4 items-center justify-center" *ngIf="!edit && (currentUserRole === Role.BOARD_MEMBER)">
      <promis-button (click)="cancel()">Tilbage til forsiden</promis-button>
    </div>
  </form>
</ng-template>
